// import { Controller } from "@hotwired/stimulus"
// import SlimSelect from 'slim-select'
// Import and register the component
// import StimulusSlimSelect from "stimulus-slimselect"
//
// // Connects to data-controller="slim-select"
// export default class extends StimulusSlimSelect {
//   connect() {
//     super.connect()
//   }
//
//   disconnect() {
//     super.disconnect()
//   }
// }
