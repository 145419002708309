import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { isCollapsed: Boolean }

  click(event) {
    this.isCollapsedValue = !this.isCollapsedValue
    const content = this.isCollapsedValue
        ? '<i class="bi bi-eye"></i> Show filters'
        : '<i class="bi bi-eye-slash"></i> Hide filters'
    this.element.innerHTML = content
  }

}