import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="slim"
export default class extends Controller {
  static values = { closeOnSelect: Boolean, hideSelected: Boolean, allowDeselect: Boolean, placeholder: String }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: this.closeOnSelectValue,
        searchText: 'Sorry nothing to see here',
        placeholderText: this.placeholderValue || 'Select as many certificates as needed',
        hideSelected: this.hideSelectedValue || true,
        allowDeselect: this.allowDeselectValue || false
      }
    })
  }

  disconnect() {
    this.select.destroy()
  }
}


// export default class extends StimulusSlimSelect {
//   connect() {
//     super.connect()
//     new SlimSelect({
//       select: `#${this.element.id}`,
//       settings: {
//         closeOnSelect: false,
//         searchText: 'Sorry nothing to see here',
//         placeholderText: 'Custom Placeholder Text',
//         hideSelected: true,
//       }
//     })
//   }
// }

