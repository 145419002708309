import NestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="nested-form"
export default class extends NestedForm {
  static values = { destination: String }

  connect() {
    super.connect()
  }

  connect() {
    super.connect()
  }

  async toggleMany(event) {
    event.preventDefault()
    // const addButton = document.querySelector("#add-button")
    const elements = document.querySelectorAll(this.wrapperSelectorValue)
    const inputValue = Number(event.currentTarget.value)
    let diff

    if (!!elements.length) {
      if (elements.length < inputValue) {
        diff = inputValue - elements.length
        for (let i = 0; i < diff; i++) {
          super.add(event)
          // addButton.click()
          await this.sleep(10)
        }
      } else if (elements.length > inputValue) {
        diff = elements.length - inputValue

        while (diff > 0) {
          const element = elements[diff - 1]

          if (element) {
            const removeButton = element.querySelector("button")
            if (removeButton) {
              removeButton.click()
            }
          }

          --diff
        }
      }
    } else {
      for (let i = 0; i < inputValue; i++) {
        super.add(event)
        // addButton.click()
        await this.sleep(10)
      }
    }
  }

  updateInput(event) {
    console.log({event})
    if (event.pointerType === "mouse") {
      const elements = document.querySelectorAll(this.wrapperSelectorValue)
      const target = document.querySelector(this.destinationValue)
      target.value = elements.length
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
